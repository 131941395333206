export default class ClientJwtServices {
  axiosIns = null

  jwtConfig = null

  constructor(axiosIns, jwtConfig) {
    this.axiosIns = axiosIns;
    this.jwtConfig = jwtConfig;
  }

  /** Personal CV */
  fetchScore() {
    return this.axiosIns.get(`${this.jwtConfig.client.clientCVEndpoint}/score`, {})
  }

  fetchCV() {
    return this.axiosIns.get(`${this.jwtConfig.client.clientCVEndpoint}`, {})
  }

  updateCV(id, update) {
    return this.axiosIns.put(`${this.jwtConfig.client.clientCVEndpoint}/${id}`, update, {});
  }

  /** Work Experience */
  createWorkExperience(payload) {
    return this.axiosIns.post(`${this.jwtConfig.client.clientCVEndpoint}/work-experience`, payload, {});
  }

  fetchWorkExperience(id) {
    return this.axiosIns.get(`${this.jwtConfig.client.clientCVEndpoint}/work-experience/${id}`, {})
  }

  updateWorkExperience(id, update) {
    return this.axiosIns.put(`${this.jwtConfig.client.clientCVEndpoint}/work-experience/${id}`, update, {});
  }

  deleteWorkExperience(id) {
    return this.axiosIns.delete(`${this.jwtConfig.client.clientCVEndpoint}/work-experience/${id}`, {})
  }

  fetchWorkExperiences(params = {}) {
    return this.axiosIns.get(`${this.jwtConfig.client.clientCVEndpoint}/work-experience`, { params })
  }

  /** Work Experience */
  createEducationHistory(payload) {
    return this.axiosIns.post(`${this.jwtConfig.client.clientCVEndpoint}/education-history`, payload, {});
  }

  fetchEducationHistory(id) {
    return this.axiosIns.get(`${this.jwtConfig.client.clientCVEndpoint}/education-history/${id}`, {})
  }

  updateEducationHistory(id, update) {
    return this.axiosIns.put(`${this.jwtConfig.client.clientCVEndpoint}/education-history/${id}`, update, {});
  }

  deleteEducationHistory(id) {
    return this.axiosIns.delete(`${this.jwtConfig.client.clientCVEndpoint}/education-history/${id}`, {})
  }

  fetchEducationHistories(params = {}) {
    return this.axiosIns.get(`${this.jwtConfig.client.clientCVEndpoint}/education-history`, { params })
  }

  /** Skill */
  createSkill(payload) {
    return this.axiosIns.post(`${this.jwtConfig.client.clientCVEndpoint}/skills`, payload, {});
  }

  fetchSkill(id) {
    return this.axiosIns.get(`${this.jwtConfig.client.clientCVEndpoint}/skills/${id}`, {})
  }

  updateSkill(id, update) {
    return this.axiosIns.put(`${this.jwtConfig.client.clientCVEndpoint}/skills/${id}`, update, {});
  }

  deleteSkill(id) {
    return this.axiosIns.delete(`${this.jwtConfig.client.clientCVEndpoint}/skills/${id}`, {})
  }

  fetchSkills(params = {}) {
    return this.axiosIns.get(`${this.jwtConfig.client.clientCVEndpoint}/skills`, { params })
  }

  /** Voluntary Service */
  createVoluntaryService(payload) {
    return this.axiosIns.post(`${this.jwtConfig.client.clientCVEndpoint}/voluntary-services`, payload, {});
  }

  fetchVoluntaryService(id) {
    return this.axiosIns.get(`${this.jwtConfig.client.clientCVEndpoint}/voluntary-services/${id}`, {})
  }

  updateVoluntaryService(id, update) {
    return this.axiosIns.put(`${this.jwtConfig.client.clientCVEndpoint}/voluntary-services/${id}`, update, {});
  }

  deleteVoluntaryService(id) {
    return this.axiosIns.delete(`${this.jwtConfig.client.clientCVEndpoint}/voluntary-services/${id}`, {})
  }

  fetchVoluntaryServices(params = {}) {
    return this.axiosIns.get(`${this.jwtConfig.client.clientCVEndpoint}/voluntary-services`, { params })
  }

  /** Hobbies and Interests */
  createHobbiesAndInterest(payload) {
    return this.axiosIns.post(`${this.jwtConfig.client.clientCVEndpoint}/hobbies-and-interests`, payload, {});
  }

  fetchHobbiesAndInterest(id) {
    return this.axiosIns.get(`${this.jwtConfig.client.clientCVEndpoint}/hobbies-and-interests/${id}`, {})
  }

  updateHobbiesAndInterest(id, update) {
    return this.axiosIns.put(`${this.jwtConfig.client.clientCVEndpoint}/hobbies-and-interests/${id}`, update, {});
  }

  deleteHobbiesAndInterest(id) {
    return this.axiosIns.delete(`${this.jwtConfig.client.clientCVEndpoint}/hobbies-and-interests/${id}`, {})
  }

  fetchHobbiesAndInterests(params = {}) {
    return this.axiosIns.get(`${this.jwtConfig.client.clientCVEndpoint}/hobbies-and-interests`, { params })
  }

  /** References */
  createReferee(payload) {
    return this.axiosIns.post(`${this.jwtConfig.client.clientCVEndpoint}/references`, payload, {});
  }

  fetchReferee(id) {
    return this.axiosIns.get(`${this.jwtConfig.client.clientCVEndpoint}/references/${id}`, {})
  }

  updateReferee(id, update) {
    return this.axiosIns.put(`${this.jwtConfig.client.clientCVEndpoint}/references/${id}`, update, {});
  }

  deleteReferee(id) {
    return this.axiosIns.delete(`${this.jwtConfig.client.clientCVEndpoint}/references/${id}`, {})
  }

  fetchReferees(params = {}) {
    return this.axiosIns.get(`${this.jwtConfig.client.clientCVEndpoint}/references`, { params })
  }

  // Track Cv 
  fetchCVActions(params = {}) {
    return this.axiosIns.get(`${this.jwtConfig.client.clientEndpoint}/cv-actions`, { params })
  }

  respondCVAction(id, payload) {
    return this.axiosIns.post(`${this.jwtConfig.client.clientEndpoint}/cv-actions/${id}/respond`, payload, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  }

  fetchJobApplications(params = {}) {
    return this.axiosIns.get(`${this.jwtConfig.client.clientEndpoint}/jobs/applications`, { params })
  }

  // Dashboard
  fetchDashboardData() {
    return this.axiosIns.get(`${this.jwtConfig.client.clientEndpoint}/dashboard`, {})
  }

  initializePayment() {
    return this.axiosIns.get(`${this.jwtConfig.client.clientEndpoint}/initialize-payment`, {})
  }

  verifyPayment(reference) {
    return this.axiosIns.get(`${this.jwtConfig.client.clientEndpoint}/verify-payment/${reference}`, {})
  }

  applyJob(id) {
    return this.axiosIns.post(`${this.jwtConfig.client.clientEndpoint}/jobs/${id}/apply`, {})
  }

  checkForApplication(id) {
    return this.axiosIns.get(`${this.jwtConfig.client.clientEndpoint}/jobs/${id}/status`, {})
  }
}

