export default class MFAJwtServices {
  axiosIns = null

  jwtConfig = null

  constructor(axiosIns, jwtConfig) {
    this.axiosIns = axiosIns;
    this.jwtConfig = jwtConfig;
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setMfaToken(value) {
    localStorage.setItem(this.jwtConfig.storageMFATokenKeyName, value);
  }

  setMfaDeactivationToken(value) {
    localStorage.setItem(this.jwtConfig.storageMFADeactivationToken, value)
  }

  setMFAPasswordResetToken(value) {
    localStorage.setItem(this.jwtConfig.storageMFAPasswordResetToken, value)
  }

  getMFAPasswordResetToken() {
    return localStorage.getItem(this.jwtConfig.storageMFAPasswordResetToken)
  }

  clearMFAPasswordResetToken() {
    localStorage.removeItem(this.jwtConfig.storageMFAPasswordResetToken)
  }

  getAccessToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getMfaDeactivationToken() {
    return localStorage.getItem(this.jwtConfig.storageMFADeactivationToken);
  }

  getMfaAccessToken() {
    return localStorage.getItem(this.jwtConfig.storageMFATokenKeyName);
  }

  clearMfaAccessToken() {
    localStorage.removeItem(this.jwtConfig.storageMFATokenKeyName)
  }

  clearMfaDeactivationToken() {
    localStorage.removeItem(this.jwtConfig.storageMFADeactivationToken)
  }

  /**
   * @param args
   * @returns sends otp to begin mfa process to chosen mfa  method
   */
  selectMfaMethod(...args) {
    return this.axiosIns.post(this.jwtConfig.mfa.enrollMfaEndpoint, ...args,);
  }

  /**
   * @param args this includes an object of the token
   */
  resendMfaOtp(...args) {
    return this.axiosIns.post(this.jwtConfig.mfa.resendMfaOTPEndpoint, ...args, {
      headers: {
        Authorization: `Bearer ${this.getMfaAccessToken()}`
      }
    });
  }

  /**
   * @param args payload with token and mfa method to authenticate user;
   *
   * */
  verifyAndAuthenticate(...args) {
    return this.axiosIns.post(this.jwtConfig.mfa.validateAndAuthenticateEndpoint, ...args, {
      headers: {
        Authorization: `Bearer ${this.getMfaAccessToken()}`
      }
    });
  }

  recoverMFAAccount(...args) {
    return this.axiosIns.post(this.jwtConfig.mfa.recoverMfaAccount, ...args, {
      headers: {
        Authorization: `Bearer ${this.getMfaAccessToken()}`
      }
    });
  }


  verifyMFAPasswordResetRequest(...args) {
    return this.axiosIns.post(this.jwtConfig.mfa.verifyMFAPasswordResetRequestEndpoint, ...args, {
      headers: {
        Authorization: `Bearer ${this.getMFAPasswordResetToken()}`
      }
    });
  }

  verifyAndActivate(...args) {
    return this.axiosIns.post(this.jwtConfig.mfa.verifyAndActivateMfaEndpoint, ...args)
  }

  deactivateMfaRequest() {
    return this.axiosIns.post(this.jwtConfig.mfa.requestDeactivateMfaEndpoint);
  }

  deactivateMfa(payload) {
    return this.axiosIns.post(this.jwtConfig.mfa.deactivateMfaEndpoint, payload, {
      headers: {
        Authorization: `Bearer ${this.getMfaDeactivationToken()}`
      }
    });
  }
}
