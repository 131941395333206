import useJwt from '@/auth/jwt/useJwt'

export const isUserLoggedIn = () => {
  return localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
}

export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

export const getHomeRouteForLoggedInUser = userType => {
  if (userType === 'client') return { name: 'client-home' }
  if (userType === 'provider') return { name: 'provider-home' }
  return { name: 'auth-login' }
}
