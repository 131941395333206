import useJwt from '@/auth/jwt/useJwt'
import store from "@/store"

import { get } from "lodash"
import {
  MUTATE_SETTINGS,
  MUTATE_USER_ABILITIES
} from "@/store/config/mutation-keys"

import ability from './ability'

export const canNavigate = (to, allowed_role = "anonymous") => {
  return to && to.matched && to.matched.some(route => {
    const action = route.meta.action || 'read';
    const subject = route.meta.resource || 'all';
    const supported_roles = route.meta.roles || [];
    return ability.can(action, subject) && supported_roles.includes(allowed_role)
  })
}

export const _ = undefined


export const checkLoginTokenStatus = async () => {
  const jsonWebTokenValidityResponse = await useJwt.authService.confirmTokenValidity("web_token");
  const { authorized, user, abilities, settings, client_group } = get(jsonWebTokenValidityResponse, 'data.data', {});

  ability.update(abilities); abilities
  store.commit(`auth/${MUTATE_USER_ABILITIES}`, abilities);

  return { authorized, user, abilities, settings, client_group };
}

export const getSignUpSettings = async () => {
  try {
    const response = await useJwt
      .authService
      .getRegistrationSettings();
    const { sign_ups_disabled, password_complexity_settings } = response.data.data;
    store.commit(`auth/${MUTATE_SETTINGS}`, { password_complexity_settings });
    return sign_ups_disabled;
  } catch (error) {
    return false
  }
}
