import store from "@/store"
import MFAJwtServices from "@core/api/services/mfa";
import AuthJwtServices from "@/@core/api/services/auth";
import SharedJwtServices from "@/@core/api/services/shared";
import ClientJwtServices from "@/@core/api/services/client";
import ProviderJwtServices from "@/@core/api/services/provider";

import { get } from "lodash";

import jwtDefaultConfig from './jwtDefaultConfig'

export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // For Refreshing Token
  subscribers = [];

  // Services;
  authService = null;

  mfaService = null;

  clientService = null;

  sharedService = null;

  providerService = null;

  constructor(axiosIns, jwtOverrideConfig) {
    this.axiosIns = axiosIns
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    this.authService = new AuthJwtServices(this.axiosIns, this.jwtConfig);
    this.mfaService = new MFAJwtServices(this.axiosIns, this.jwtConfig);
    this.clientService = new ClientJwtServices(this.axiosIns, this.jwtConfig);
    this.sharedService = new SharedJwtServices(this.axiosIns, this.jwtConfig);
    this.providerService = new ProviderJwtServices(this.axiosIns, this.jwtConfig)

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      config => {
        // eslint-disable-next-line no-undef
        NProgress.start();
        store.commit(`navigation/UPDATE_FETCHING_REMOTE_DATA`, true)

        // Get token from localStorage
        const accessToken = this.authService.getAccessToken();
        const hasCustomToken = !!get(config, `headers.Authorization`);

        if (!hasCustomToken && accessToken) {
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        }
        return config
      },
      error => {
        // eslint-disable-next-line no-undef
        NProgress.done();
        store.commit(`navigation/UPDATE_FETCHING_REMOTE_DATA`, false)



        Promise.reject(error)
      }
    );

    this.axiosIns.interceptors.response.use(
      response => {
        // eslint-disable-next-line no-undef
        NProgress.done()
        store.commit(`navigation/UPDATE_FETCHING_REMOTE_DATA`, false);

        return response;
      },
      (error) => {
        // eslint-disable-next-line no-undef
        NProgress.done();
        store.commit(`navigation/UPDATE_FETCHING_REMOTE_DATA`, false)

        // log user put when an invalid access token occurs
        return Promise.reject(error);
      }
    );
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }
}
