import {
  MUTATE_USER_ABILITIES,
  MUTATE_LOGIN_STATUS,
  MUTATE_PHONE_VERIFICATION_STATUS,
  MUTATE_USER_DATA,
  MUTATE_SETTINGS,
  MUTATE_ONLINE_USERS,
  MUTATE_USER_TICKET_NOTIFICATIONS,
  MUTATE_USER_SYSTEM_NOTIFICATIONS
} from "@/store/config/mutation-keys"

const initialState = {
  isLoggedIn: false,
  isPhoneVerified: false,
  userData: {},
  settings: {},
  onlineUsers: [],
  abilities: [],
  userSupportNotifications: [],
  userSystemNotifications: []
};

const mutations = {
  [MUTATE_USER_ABILITIES](state, abilities) {
    state.abilities = abilities
  },
  [MUTATE_LOGIN_STATUS](state, loginStatus) {
    state.isLoggedIn = loginStatus
  },
  [MUTATE_PHONE_VERIFICATION_STATUS](state, phoneVerificationStatus) {
    state.isPhoneVerified = phoneVerificationStatus
  },
  [MUTATE_USER_DATA](state, payload) {
    state.userData = payload;
  },
  [MUTATE_SETTINGS](state, payload) {
    state.settings = payload;
  },
  [MUTATE_ONLINE_USERS](state, online_users) {
    state.onlineUsers = online_users
  },
  [MUTATE_USER_TICKET_NOTIFICATIONS](state, userSupportNotifications) {
    state.userSupportNotifications = userSupportNotifications
  },
  [MUTATE_USER_SYSTEM_NOTIFICATIONS](state, userSystemNotifications) {
    state.userSystemNotifications = userSystemNotifications
  }
}
const actions = {}
const getters = {
  isLoggedIn: (state) => state.isLoggedIn,
  isPhoneVerified: (state) => state.isPhoneVerified,
  userData: (state) => state.userData,
  settings: (state) => state.settings,
  onlineUsers: (state) => state.onlineUsers,
  userSupportNotifications: (state) => state.userSupportNotifications,
  userSystemNotifications: (state) => state.userSystemNotifications
}

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters
}
