export default class SharedJwtServices {
  axiosIns = null

  jwtConfig = null

  constructor(axiosIns, jwtConfig) {
    this.axiosIns = axiosIns;
    this.jwtConfig = jwtConfig;
  }

  /** faqs */
  fetchFaqs(params) {
    return this.axiosIns.get(`${this.jwtConfig.shared.sharedFaqsEndpoint}`, {
      params
    })
  }

  /** Blog POSTS */
  fetchBlogPosts(params) {
    return this.axiosIns.get(`${this.jwtConfig.shared.sharedBlogsEndpoint}`, {
      params
    })
  }

  fetchBlogPost(slug) {
    return this.axiosIns.get(`${this.jwtConfig.shared.sharedBlogsEndpoint}/${slug}`, {})
  }

  fetchRecentBlogPosts() {
    return this.axiosIns.get(`${this.jwtConfig.shared.sharedBlogsEndpoint}/query/recent`, {})
  }

  submitBlogComment(blog_id, params) {
    return this.axiosIns.post(`${this.jwtConfig.shared.sharedBlogsEndpoint}/${blog_id}/comment`, params)
  }

  /** Countries */
  fetchCountries(params) {
    return this.axiosIns.get('countries', { params })
  }

  fetchGeoData(params) {
    return this.axiosIns.get('geo-data', { params })
  }

  fetchChurchRegions() {
    return this.axiosIns.get('regions', {})
  }

  fetchChurchAreas(region) {
    return this.axiosIns.get(`regions/${region}/areas`, {})
  }

  fetchChurchDistricts(region, area) {
    return this.axiosIns.get(`regions/${region}/areas/${area}/districts`, {})
  }

  fetchChurchAssemblies(region, area, district) {
    return this.axiosIns.get(`regions/${region}/areas/${area}/districts/${district}/assemblies`, {})
  }

  fetchJobs(params = {}) {
    return this.axiosIns.get(`jobs`, { params })
  }

  fetchJob(id) {
    return this.axiosIns.get(`jobs/${id}`, {})
  }

  search(params = {}) {
    return this.axiosIns.get(`search/autocomplete`, {
      params
    })
  }

  fetchCandidates() {
    return this.axiosIns.get(`candidates`, {})
  }

  fetchProviders() {
    return this.axiosIns.get(`providers`, {})
  }
}

