const Personal = () => import('@/pages/client/manage-cv/tabs/Personal')
const Address = () => import('@/pages/client/manage-cv/tabs/Address')
const WorkExperience = () => import('@/pages/client/manage-cv/tabs/WorkExperience')
const EducationHistory = () => import('@/pages/client/manage-cv/tabs/EducationHistory')
const Skills = () => import('@/pages/client/manage-cv/tabs/Skills')
const VoluntaryServices = () => import('@/pages/client/manage-cv/tabs/VoluntaryServices')
const HobbiesAndInterests = () => import('@/pages/client/manage-cv/tabs/HobbiesAndInterests')
const References = () => import('@/pages/client/manage-cv/tabs/References')


const JobApplications = () => import('@/pages/client/track/tabs/JobApplications')
const ApplicationLetterRequests = () => import('@/pages/client/track/tabs/ApplicationLetterRequests')
const InterviewRequests = () => import('@/pages/client/track/tabs/InterviewRequests')

const cvChildRouteDefs = [
  {
    path: 'genesis',
    component: Personal,
    title: 'Genesis',
  },
  {
    path: 'address',
    component: Address,
    title: 'Address',
  },
  {
    path: 'work-experience',
    title: 'Work Experience',
    component: WorkExperience,
  },
  {
    path: 'education-history',
    component: EducationHistory,
    title: 'Education History',
  },
  {
    path: 'skills',
    component: Skills,
    title: 'Skills',
  },
  {
    path: 'voluntary-services',
    component: VoluntaryServices,
    title: 'Volunteer Work & Community Involvement',
  },
  {
    path: 'hobbies',
    component: HobbiesAndInterests,
    title: 'Hobbies & Interests',
  },
  {
    path: 'references',
    component: References,
    title: 'References',
  },
];

const mappedChildCVRoutes = cvChildRouteDefs.map(child => ({
  path: child.path,
  name: child.path,
  meta: {
    pageTitle: child.title,
    action: "read",
    resource: "ClientRoute",
    breadcrumb: [
      {
        text: 'Home',
        active: true,
      }
    ],
    requireAuth: true,
    logPageName: `Job Seeker ${child.title}`,
    roles: ["client"]
  },
  component: child.component,
}));

const trackChildRouteDefs = [
  {
    path: 'job-applications',
    component: JobApplications,
    title: 'Job Applications',
  },
  {
    path: 'letter-requests',
    component: ApplicationLetterRequests,
    title: 'Application Letter Requests',
  },
  {
    path: 'interview-requests',
    title: 'Interview Requests',
    component: InterviewRequests,
  },
];

const mappedChildTrackRoutes = trackChildRouteDefs.map(child => ({
  path: child.path,
  name: child.path,
  meta: {
    pageTitle: child.title,
    action: "read",
    resource: "ClientRoute",
    breadcrumb: [
      {
        text: 'Home',
        active: true,
      }
    ],
    requireAuth: true,
    logPageName: `Job Seeker ${child.title}`,
    roles: ["client"]
  },
  component: child.component,
}));

export default [
  {
    path: '/job-seeker/home',
    name: 'client-home',
    component: () => import('@/pages/client/home'),
    beforeEnter: (to, _, next) => {
      const last_page_accessed = sessionStorage.getItem('last_page_accessed');
      if (
        last_page_accessed &&
        last_page_accessed !== to.name &&
        !last_page_accessed.includes("auth")
      ) {
        sessionStorage.removeItem("last_page_accessed");
        return next({ path: last_page_accessed });
      }
      return next();
    },
    meta: {
      pageTitle: 'Home',
      action: "read",
      resource: "ClientRoute",
      breadcrumb: [
        {
          text: 'Home',
          active: true,
        }
      ],
      requireAuth: true,
      logPageName: "Client Dashboard",
      roles: ["client"]
    },
  },
  {
    path: '/job-seeker/jobs',
    name: 'client-jobs',
    component: () => import('@/pages/client/jobs/list'),
    meta: {
      pageTitle: 'Jobs',
      action: "read",
      resource: "ClientRoute",
      breadcrumb: [
        {
          text: 'Jobs',
          active: true,
        }
      ],
      roles: ["client"],
      requireAuth: true,
      showPagination: true,
      logPageName: "Client List Jobs",
      contentClass: '',
    },
  },
  {
    path: '/job-seeker/jobs/:job_id',
    name: 'client-single-job',
    component: () => import('@/pages/client/jobs/view'),
    meta: {
      pageTitle: 'Apply Job',
      action: "read",
      resource: "ClientRoute",
      breadcrumb: [
        {
          text: 'Jobs',
          to: {
            name: 'client-jobs'
          }
        },
        {
          text: 'Apply',
          active: true,
        }
      ],
      roles: ["client"],
      requireAuth: true,
      showPagination: true,
      logPageName: "Client Job Details",
      contentClass: '',
    },
  },
  {
    path: '/job-seeker/track',
    name: 'track-app',
    component: () => import('@/pages/client/track'),
    redirect: { name: 'job-applications' },
    children: mappedChildTrackRoutes,
  },
  {
    path: '/job-seeker/manage-cv',
    name: 'manage-cv',
    component: () => import('@/pages/client/manage-cv'),
    redirect: { name: 'genesis' },
    children: mappedChildCVRoutes,
  },
  {
    path: '/job-seeker/resources',
    name: 'client-blogs',
    component: () => import('@/pages/client/blogs/list'),
    meta: {
      pageTitle: 'Resources',
      action: "read",
      resource: "ClientRoute",
      breadcrumb: [
        {
          text: 'Resources',
          active: true,
        }
      ],
      roles: ["client"],
      requireAuth: true,
      showPagination: true,
      logPageName: "Client List Resources",
      contentClass: '',
    },
  },
  {
    path: '/job-seeker/resources/:slug',
    name: 'client-single-blog',
    component: () => import('@/pages/client/blogs/view'),
    meta: {
      pageTitle: 'Resource Details',
      action: "read",
      resource: "ClientRoute",
      breadcrumb: [
        {
          text: 'Home',
          to: { name: 'client-home' },
        },
        {
          text: 'Resources',
          to: { name: 'client-blogs' },
        },
        {
          text: 'Details',
          active: true,
        }
      ],
      requireAuth: true,
      logPageName: "View Resource Details",
      roles: ["client"]
    },
  },
  {
    path: '/job-seeker/contact-us',
    name: 'client-contact-us',
    component: () => import('@/pages/client/contact-us'),
    meta: {
      pageTitle: 'Resource Details',
      action: "read",
      resource: "ClientRoute",
      breadcrumb: [
        {
          text: 'Home',
          to: { name: 'client-home' },
        },
        {
          text: 'Contact Us',
          active: true,
        }
      ],
      requireAuth: true,
      logPageName: "View Contact Us Details",
      roles: ["client"]
    },
  },
]
