import mfaEndpoints from "./endpoints/mfa"
import authEndpoints from "./endpoints/auth";
import sharedEndpoints from "./endpoints/shared";
import clientEndpoints from "./endpoints/client";
import providerEndpoints from "./endpoints/provider";

export default {
  // Endpoints
  mfa: mfaEndpoints,
  auth: authEndpoints,
  client: clientEndpoints,
  shared: sharedEndpoints,
  provider: providerEndpoints,

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageMFATokenKeyName: 'mfaAccessToken',
  storageMFADeactivationToken: 'mfaDeactivationToken',
  storageMFAPasswordResetToken: 'mfaPasswordResetToken',
  storageConfirmAccountTokenKeyName: 'confirmAccountToken',
  storageConfirmPhoneToken: 'confirmPhoneToken',
  storagePasswordResetTokenKeyName: 'passwordResetToken',
  storageRefreshTokenKeyName: 'refreshToken',
  storageConfirmPhoneTimestamp: 'confirmPhoneTimestamp'
}
