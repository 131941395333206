<template>
  <idle-display :duration="60 * idle_timeout">
    <div
      id="app"
      class="h-100"
      :class="[skinClasses]"
    >
      <component :is="layout" v-cloak>
        <router-view :key="$route.path" />
      </component>

      <scroll-to-top v-if="enableScrollToTop" />

      <div id="sound-source" style="display: none"></div>
    </div>
  </idle-display>
</template>

<script>

// This will be populated in `beforeCreate` hook
import { get } from "lodash"
import { watch } from '@vue/composition-api'
import { useCssVar, useWindowSize } from '@vueuse/core'
import { provideToast } from 'vue-toastification/composition'
import { $themeBreakpoints, $themeColors, $themeConfig } from '@themeConfig'

import store from '@/store'
import useAppConfig from '@core/app-config/useAppConfig'

import IdleDisplay from "@core/components/shared/idle/IdleDisplay.vue"
import ScrollToTop from '@core/components/scroll-to-top/ScrollToTop.vue'

const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')
const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')

export default {
  components: {
    IdleDisplay,

    // Layouts
    LayoutFull,
    LayoutVertical,
    LayoutHorizontal,

    ScrollToTop,
  },
  setup() {
    const { skin, skinClasses } = useAppConfig()
    const { enableScrollToTop } = $themeConfig.layout

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    })

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    return {
      skinClasses,
      enableScrollToTop,
    }
  },
  data(){
    return {
      loading: false,
      loaded: false
    }
  },
  computed: {
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
    settings() {
      return this.$store.getters[`auth/settings`];
    },
    idle_timeout() {
      return get(this.settings, 'security_settings.idle_timeout', 5)
    }
  },
  watch: {
    isLoggedIn: {
      handler(isLoggedIn){
        // console.log("isLoggedIn", isLoggedIn)
        if (isLoggedIn){
          if (this.isCurrentUserClient){
            this.fetchCVScore();
            // this.fetchCVFromMixing();
          }
          
          this.$store.commit('appConfig/UPDATE_LAYOUT_TYPE', 'vertical')
          this.$store.commit('appConfig/UPDATE_FOOTER_TYPE', 'sticky')
        } else {
          this.$store.commit('appConfig/UPDATE_LAYOUT_TYPE', 'horizontal')
          this.$store.commit('appConfig/UPDATE_FOOTER_TYPE', 'static')
        }
      },
      immediate: true
    },
  },
  beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }


    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },
  methods: {},
}
</script>

<style scoped>
  [v-cloak] {
    display: none;
  }
</style>
