import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'

import i18n from '@/libs/i18n'
import socketio from 'socket.io-client';
import VueSocketio from 'vue-socket.io-extended';
import GeneralMixin from "@/@core/mixins/general";
import VueCompositionAPI from '@vue/composition-api'
import VueHtmlToPaper from "vue-html-to-paper";

// import VueScrollTo from "vue-scrollto";
import VueSmoothScroll from 'vue2-smooth-scroll'

import router from '@router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'

const options = {
  name: "_blank",
  specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
  styles: [
    "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css",
    "https://unpkg.com/kidlat-css/css/kidlat.css"
  ]
};

Vue.use(VueHtmlToPaper, options);


// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(VueSmoothScroll, {
  duration: 400,
  updateHistory: false,
})

// Composition API
Vue.use(VueCompositionAPI)

const socket = socketio(process.env.VUE_APP_SOCKET_URL, {
  autoConnect: false,
  reconnection: true,
  transports: ["websocket"],
  reconnectionDelay: 500,
  maxReconnectionAttempts: Infinity
});

Vue.use(VueSocketio, socket);
Vue.mixin(GeneralMixin);

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
