export const initialAbility = [
  {
    action: 'read',
    subject: 'AppRoot',
  },
  {
    action: 'read',
    subject: 'AuthRoute',
  },
  {
    action: 'read',
    subject: 'PublicPage',
  },
  {
    action: 'read',
    subject: 'PublicRoute',
  },
]

export const _ = undefined
