import Vue from 'vue'

// axios
import axios from 'axios'
import { set } from 'lodash';

const {
  VUE_APP_SERVER_URL = '',
} = process.env;

// const baseURL = VUE_APP_PLATFORM === 'partner' ? VUE_APP_PARTNER_SERVER_URL : VUE_APP_PLATFORM === 'omc' ? VUE_APP_OMC_SERVER_URL : VUE_APP_SERVER_URL;

const baseURL = () => {
  return VUE_APP_SERVER_URL;
}

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: baseURL(),
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})


const errorInterceptor = (error) => {
  const target_status_codes = [413, 408, 504, 502];
  const error_message = "There was an error communicating with the server. Try again later.";
  if (!error.response && error.message === "Network Error") {
    error.message = error_message;
  } else if (target_status_codes.includes(error.response.status)) {
    set(error, "response.data.message", error_message);
  }

  return Promise.reject(error);
}

const successInterceptor = (response) => {
  return response;
}

axiosIns.interceptors.response.use(successInterceptor, errorInterceptor);

Vue.prototype.$http = axiosIns

export default axiosIns
