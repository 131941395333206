export default class ProviderJwtServices {
  axiosIns = null

  jwtConfig = null

  constructor(axiosIns, jwtConfig) {
    this.axiosIns = axiosIns;
    this.jwtConfig = jwtConfig;
  }

  fetchProvider() {
    return this.axiosIns.get(`${this.jwtConfig.provider.providerEndpoint}`, {})
  }

  updateProvider(update) {
    return this.axiosIns.put(`${this.jwtConfig.provider.providerEndpoint}`, update, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  }

  fetchJobs(params = {}) {
    return this.axiosIns.get(`${this.jwtConfig.provider.providerEndpoint}/jobs`, { params })
  }

  postJob(payload) {
    return this.axiosIns.post(`${this.jwtConfig.provider.providerEndpoint}/jobs`, payload, {});
  }

  fetchJob(id) {
    return this.axiosIns.get(`${this.jwtConfig.provider.providerEndpoint}/jobs/${id}`, {})
  }

  updateJob(id, payload) {
    return this.axiosIns.put(`${this.jwtConfig.provider.providerEndpoint}/jobs/${id}`, payload, {})
  }

  deleteJob(id) {
    return this.axiosIns.delete(`${this.jwtConfig.provider.providerEndpoint}/jobs/${id}`, {})
  }

  fetchJobApplications(id) {
    return this.axiosIns.get(`${this.jwtConfig.provider.providerEndpoint}/jobs/${id}/applications`, {})
  }

  fetchCandidates(params = {}) {
    return this.axiosIns.get(`${this.jwtConfig.provider.providerEndpoint}/candidates`, { params })
  }

  fetchCandidate(id) {
    return this.axiosIns.get(`${this.jwtConfig.provider.providerEndpoint}/candidates/${id}`, {})
  }

  actionCV({ id, subject, options = {} }) {
    return this.axiosIns.post(`${this.jwtConfig.provider.providerEndpoint}/candidates/${id}/action/${subject}`, { ...options });
  }

  undoCVAction(id) {
    return this.axiosIns.post(`${this.jwtConfig.provider.providerEndpoint}/action/${id}/cancel`, {});
  }

  fetchActionedCandidates(params = {}) {
    return this.axiosIns.get(`${this.jwtConfig.provider.providerEndpoint}/actioned-candidates`, { params })
  }

  declineCandidate(id) {
    return this.axiosIns.post(`${this.jwtConfig.provider.providerEndpoint}/action/${id}/decline`, {});
  }

  employCandidate(id, payload) {
    return this.axiosIns.post(`${this.jwtConfig.provider.providerEndpoint}/action/${id}/employ`, payload);
  }

  myCandidates(params = {}) {
    return this.axiosIns.get(`${this.jwtConfig.provider.providerEndpoint}/my-candidates`, { params })
  }

  // Dashboard
  fetchDashboardData() {
    return this.axiosIns.get(`${this.jwtConfig.provider.providerEndpoint}/dashboard`, {})
  }
}

