export default {
  // Auth Endpoints
  fetchClientGroupsEndpoint: '/client-groups',
  sharedBlogsEndpoint: '/blogs',
  sharedProductsEndpoint: '/products',
  sharedFaqsEndpoint: '/faqs',
  sharedStartersEndpoint: '/starters',
  sharedGraduateEndpoint: '/graduate',
  sharedTestimonialsEndpoint: "/testimonials",
  sharedLoanSettings: "/loan-settings",
  sharedStoriesEndpoint: '/story',
}
