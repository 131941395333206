export default [
  {
    path: '/',
    name: "app-root",
    meta: {
      action: "read",
      resource: "AppRoot",
      isPublicRoute: true,
      layout: 'horizontal',
      contentClass: 'm-0 pl-0 pr-0 pt-5x',
      redirectIfLoggedIn: true,
    },
    component: () => import('@/pages/public/home'),
  },
  {
    path: '/jobs',
    name: 'public-jobs',
    component: () => import('@/pages/public/jobs/list'),
    meta: {
      action: "read",
      pageTitle: 'Jobs',
      layout: 'horizontal',
      isPublicRoute: true,
      redirectIfLoggedIn: true,
      resource: "PublicRoute",
      logPageName: "View Jobs",
      contentClass: 'm-0 pl-2 pt-5 pr-0 pt-5x',
    },
  },
  {
    path: '/jobs/:job_id',
    name: 'single-job',
    component: () => import('@/pages/public/jobs/view'),
    meta: {
      action: "read",
      pageTitle: 'Job',
      layout: 'horizontal',
      isPublicRoute: true,
      redirectIfLoggedIn: true,
      resource: "PublicRoute",
      logPageName: "View Job Details",
      contentClass: 'm-0 pl-2 pt-5 pr-0 pt-5x',
    },
  },
  {
    path: '/about-us',
    name: "about-us",
    meta: {
      action: "read",
      layout: 'horizontal',
      isPublicRoute: true,
      resource: "PublicRoute",
      redirectIfLoggedIn: true,
      contentClass: 'm-2 pl-0 pr-0 pt-5x',
    },
    component: () => import('@/pages/public/about-us'),
  },
  {
    path: '/seekers',
    name: "seekers",
    meta: {
      action: "read",
      layout: 'horizontal',
      isPublicRoute: true,
      resource: "PublicRoute",
      redirectIfLoggedIn: true,
      contentClass: 'm-0 pl-0 pr-0 pt-5x',
    },
    component: () => import('@/pages/public/seekers'),
  },
  {
    path: '/providers',
    name: "providers",
    meta: {
      action: "read",
      isPublicRoute: true,
      layout: 'horizontal',
      resource: "PublicRoute",
      redirectIfLoggedIn: true,
      contentClass: 'm-0 pl-0 pr-0 pt-5x',
    },
    component: () => import('@/pages/public/providers'),
  },
  {
    path: '/blog',
    name: "resources",
    meta: {
      action: "read",
      layout: 'horizontal',
      isPublicRoute: true,
      resource: "PublicRoute",
      redirectIfLoggedIn: true,
      contentClass: 'm-0 pl-0 pr-0 pt-5x',
    },
    component: () => import('@/pages/public/blog/list'),
  },
  {
    path: '/resources/:slug',
    name: 'single-blog',
    component: () => import('@/pages/public/blog/view'),
    meta: {
      action: "read",
      pageTitle: 'Blogs',
      layout: 'horizontal',
      isPublicRoute: true,
      redirectIfLoggedIn: true,
      resource: "PublicRoute",
      logPageName: "View Blog Details",
      contentClass: 'm-0 pl-2 pt-5 pr-0 pt-5x',
    },
  },
  {
    path: '/privacy-policy',
    name: "privacy",
    meta: {
      layout: 'full',
      action: "read",
      isPublicRoute: true,
      resource: "PublicRoute",
      contentClass: 'm-0 pl-0 pr-0 pt-5x',
    },
    component: () => import('@/pages/public/privacy'),
  },
  {
    path: '/terms',
    name: "terms",
    meta: {
      layout: 'full',
      action: "read",
      isPublicRoute: true,
      resource: "PublicRoute",
      contentClass: 'm-0 pl-0 pr-0 pt-5x',
    },
    component: () => import('@/pages/public/terms'),
  }
]
