import Vue from 'vue'
import VueRouter from 'vue-router'

import store from "@/store"
import useJwt from '@/auth/jwt/useJwt'

import { get } from "lodash"
import {
  MUTATE_SETTINGS,
  MUTATE_USER_DATA,
  MUTATE_LOGIN_STATUS,
  MUTATE_PHONE_VERIFICATION_STATUS,
} from "@/store/config/mutation-keys"

// Routes
import { getHomeRouteForLoggedInUser, isUserLoggedIn } from '@/auth/utils'
import { canNavigate, checkLoginTokenStatus, getSignUpSettings } from '@/libs/acl/routeProtection'

import authRoutes from "./auth";
import publicRoutes from "./public";
import clientRoutes from "./client";
import ProviderRoutes from "./provider";

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    ...publicRoutes,
    ...authRoutes,
    ...clientRoutes,
    ...ProviderRoutes,
    {
      path: '*',
      name: 'error-404',
      component: () => import('@/pages/auth/error-404'),
      meta: {
        layout: 'full',
        resource: 'AuthRoute',
        action: 'read',
        roles: ["client", "provider", "anonymous"]
      },
    },
  ],
})


router.beforeEach(async (to, from, next) => {
  try {
    const { token: hasAccountOrPasswordConfirmationToken } = to.query;

    const toPathRequiresAuth = get(to, 'meta.requireAuth', false);
    const redirectIfLoggedIn = get(to, 'meta.redirectIfLoggedIn', false);

    const isLoggedIn = isUserLoggedIn();

    //* route validations

    /**
     * a user is not allowed to manually visit 'auth-register-success' manually
     * except they came from 'auth-register'
     */
    if (to.name === "auth-register-confirm-email") {
      if (from.name !== "auth-register" || !sessionStorage.getItem("registration_email")) {
        return next({ name: 'auth-login' });
      }
      return next();
    }


    const routesRequiringPasswordSettings = [
      "auth-register",
      "auth-reset-password"
    ];

    if (routesRequiringPasswordSettings.includes(to.name)) {
      // check if user has disabled new sign ups
      const is_sign_ups_disabled = await getSignUpSettings();

      if (to.name === "auth-register") {
        if (is_sign_ups_disabled) return next({ name: 'auth-no-sign_ups' })
      }
    }

    /**
     * password reset route handle
     */
    if (hasAccountOrPasswordConfirmationToken && to.name === "auth-reset-password") {
      // console.log("---> has password reset token");
      const { token: passwordResetToken } = to.query;
      if (!passwordResetToken) {
        store.commit(`auth/${MUTATE_LOGIN_STATUS}`, false)
        return next({ name: "auth-login" });
      }

      try {
        useJwt.authService.setPasswordResetToken(passwordResetToken);
        await useJwt.authService.confirmTokenValidity("password_reset_token");
        return next();
      } catch (error) {
        return next({ name: "auth-forgot-password", query: { error: "Password reset token has expired" } });
      }
    }

    /**
     * this validation is for routes that only allow access if certain prop is not available.
     */
    const supportedRoutesWithPropValidations = [
      "auth-verify-phone",
      "client-onboarding",
      "provider-onboarding",
    ];

    if (supportedRoutesWithPropValidations.includes(to.name) && isLoggedIn) {
      const { authorized, user } = await checkLoginTokenStatus()
      // console.log("---> authorized", authorized);

      if (!authorized) {
        return next({ name: 'auth-login' });
      }

      const phoneNumberVerified = get(user, 'meta.phone_verified', false);
      const { has_completed_onboarding: onboardingCompleted = false, user_type } = user;

      // redirects if phone number already verified
      const isClientOrProvider = ["client", "provider"].includes(user_type);

      if (isClientOrProvider && phoneNumberVerified && to.name === "auth-verify-phone") {
        return next("/");
      }

      if (isClientOrProvider && onboardingCompleted && (to.name === "Client-onboarding" || to.name === "provider-onboarding")) {
        return next("/");
      }
    }

    /**
     * path requires authentication but user is logged in.
     */
    if (toPathRequiresAuth && isLoggedIn) {
      console.log("---> requires authentication and is logged in");
      const { authorized, user, settings } = await checkLoginTokenStatus()
      // console.log("---> authorized", authorized);
      const in_maintenance_mode = get(settings, 'general_settings.enable_maintenance_mode', false)

      if (user.user_type !== "admin" && in_maintenance_mode) {
        return next({ name: 'misc-under-maintenance' });
      }

      if (!in_maintenance_mode && to.name === 'misc-under-maintenance') {
        return next("/");
      }

      if (!authorized) {
        return next({ name: 'auth-login' });
      }

      store.commit(`auth/${MUTATE_USER_DATA}`, user);
      store.commit(`auth/${MUTATE_LOGIN_STATUS}`, true);
      store.commit(`auth/${MUTATE_SETTINGS}`, settings);

      const phoneVerificationStatus = get(user, 'meta.phone_verified', false);
      const requirePhoneVerification = get(settings, 'security_settings.require_client_phone_verification', false);
      const { has_completed_onboarding: hasCompletedOnboarding = false } = user;

      store.commit(`auth/${MUTATE_PHONE_VERIFICATION_STATUS}`, phoneVerificationStatus);

      if (!phoneVerificationStatus && requirePhoneVerification) {
        console.log("---> requires phone verification but phone not verified");
        store.commit(`auth/${MUTATE_PHONE_VERIFICATION_STATUS}`, false);
        try {
          if (to.name !== "auth-verify-phone") {
            const response = await useJwt.authService.requestPhoneVerification();

            const { data } = response.data;

            if (data.sms_verification_code_timestamp) {
              useJwt.authService.setPhoneVerificationTimestamp(data.sms_verification_code_timestamp);
            }

            if (data.phone_verification_token) {
              useJwt.authService.setPhoneVerificationToken(data.phone_verification_token);
            }

            return next({ name: "auth-verify-phone" });
          }

          return next();
        } catch (error) {
          if (to.name !== "auth-verify-phone") {
            return next({ name: "auth-verify-phone" });
          }

          return next();
        }
      }

      // console.log('hasCompletedOnboarding', hasCompletedOnboarding)
      if (!hasCompletedOnboarding) {
        const onboarding_screens = ["client-onboarding", "provider-onboarding"];

        if (!onboarding_screens.includes(to.name)) {
          return next({ name: `${user.user_type}-onboarding` });
        }
      }

      if (hasCompletedOnboarding && user.user_type === "provider") {
        const admin_approved = get(user, 'meta.admin_approved', false);
        if (!admin_approved && to.name !== "pending-provider-verification") {
          return next({ name: 'pending-provider-verification' })
        }

        if (admin_approved && to.name === "pending-provider-verification") {
          return next("/");
        }
      }

      // const daysBeforePasswordExpire = user.days_before_password_expire;

      // if (daysBeforePasswordExpire <= 0 && to.name !== 'security-password-change') {
      //   return next({ name: 'security-password-change' })
      // }

      /**
       * redirect user to dashboard.
       */
      if (to.path === "/") {
        const { name } = getHomeRouteForLoggedInUser(user.user_type);

        const currentUserHomeRoute = router.resolve({ name });
        if (!currentUserHomeRoute) {
          return next();
        }

        if (canNavigate(currentUserHomeRoute.route, user.user_type)) {
          return next({ name, query: get(to, 'query') });
        }

        return next({ name: 'misc-not-authorized' })
      }


      // check is user has access to route.
      if (canNavigate(to, user.user_type)) {
        // console.log("---> all good proceed");
        return next();
      }

      // console.log("---> not allowed");
      return next({ name: 'misc-not-authorized' });
    }

    /**
     * path requires authentication but user is not logged in.
     */
    if (toPathRequiresAuth && !isLoggedIn) {
      // console.log("---> requires authentication and is not logged in");
      return next({ name: 'auth-login' });
    }

    if (!toPathRequiresAuth && isLoggedIn && redirectIfLoggedIn) {
      // console.log("---> requires no authentication with redirect and is logged in");
      const { authorized, user, settings } = await checkLoginTokenStatus()

      const in_maintenance_mode = get(settings, 'general_settings.enable_maintenance_mode', false)
      if (user.user_type !== "admin" && in_maintenance_mode) {
        if (to.name === 'misc-under-maintenance') {
          return next();
        }
        return next({ name: 'misc-under-maintenance' });
      }

      if (!in_maintenance_mode && to.name === 'misc-under-maintenance') {
        return next("/");
      }

      console.log("---> authorized", authorized);
      if (authorized) {
        store.commit(`auth/${MUTATE_USER_DATA}`, user);
        store.commit(`auth/${MUTATE_LOGIN_STATUS}`, true);
        store.commit(`auth/${MUTATE_SETTINGS}`, settings);

        const { name } = getHomeRouteForLoggedInUser(user.user_type);
        const currentUserHomeRoute = router.resolve({ name });
        if (!currentUserHomeRoute) {
          return next();
        }

        if (canNavigate(currentUserHomeRoute.route, user.user_type)) {
          return next({ name, query: get(to, "query") });
        }

        return next({ name: 'misc-not-authorized' })
      }

      // proceed to intended destination because user is not logged in.
      // here user is only redirect if they are logged in.
      store.commit(`auth/${MUTATE_LOGIN_STATUS}`, false);
      return next();
    }

    // console.log("---> requires no authentication and not logged in");
    return next()
  } catch (error) {
    useJwt.authService.clearAccessToken();

    // console.log("Login Error", error.message);
    if (!to.name !== "auth-login") {
      store.commit(`auth/${MUTATE_LOGIN_STATUS}`, false)
      next({ name: "auth-login" });
    }
    return next();
  }
})


router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    // eslint-disable-next-line no-undef
    NProgress.start()
  }
  next()
})

router.afterEach(async (to) => {
  // Complete the animation of the route progress bar.
  // eslint-disable-next-line no-undef
  NProgress.done()

  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.remove()
    // appLoading.style.display = 'none'
  }

  try {
    const requireAuth = get(to, 'meta.requireAuth');
    const hasLogPageName = get(to, 'meta.logPageName');
    const isLoggedIn = isUserLoggedIn();

    if (requireAuth && hasLogPageName && isLoggedIn) {
      await useJwt.authService.logPageAccess({
        route: to.path,
        comment: hasLogPageName
      });
    }
  } catch (error) {
    //
  }
});


export default router
