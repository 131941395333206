export default {
  namespaced: true,
  state: {
    cvScore: 0,
    cvData: null
  },
  getters: {
    cvScore: state => {
      return state.cvScore
    },
    cvData: state => {
      return state.cvData
    },
  },
  mutations: {
    UPDATE_CV_SCORE(state, val) {
      state.cvScore = val
    },
    UPDATE_CV(state, val) {
      state.cvData = val
    },
  },
  actions: {}
}
