

export default [
  {
    path: '/pending',
    name: 'pending-provider-verification',
    component: () => import('@/pages/provider/pending-verification'),
    meta: {
      layout: 'full',
      resource: 'AuthRoute',
      action: 'read',
      requireAuth: true,
      logPageName: "Provider Pending Verification",
      roles: ["provider"]
    },
  },
  // {
  //   path: '/job-provider/home',
  //   name: 'provider-home',
  //   component: () => import('@/pages/provider/home'),
  //   beforeEnter: (to, _, next) => {
  //     const last_page_accessed = sessionStorage.getItem('last_page_accessed');
  //     if (
  //       last_page_accessed &&
  //       last_page_accessed !== to.name &&
  //       !last_page_accessed.includes("auth")
  //     ) {
  //       sessionStorage.removeItem("last_page_accessed");
  //       return next({ path: last_page_accessed });
  //     }
  //     return next();
  //   },
  //   meta: {
  //     pageTitle: 'Home',
  //     action: "read",
  //     resource: "ProviderRoute",
  //     breadcrumb: [
  //       {
  //         text: 'Home',
  //         active: true,
  //       }
  //     ],
  //     requireAuth: true,
  //     logPageName: "Provider Dashboard",
  //     roles: ["provider"]
  //   },
  // },
  {
    path: '/job-provider/jobs',
    name: 'provider-home',
    component: () => import('@/pages/provider/job-posts/list'),
    beforeEnter: (to, _, next) => {
      const last_page_accessed = sessionStorage.getItem('last_page_accessed');
      if (
        last_page_accessed &&
        last_page_accessed !== to.name &&
        !last_page_accessed.includes("auth")
      ) {
        sessionStorage.removeItem("last_page_accessed");
        return next({ path: last_page_accessed });
      }
      return next();
    },
    meta: {
      pageTitle: 'Job Posts',
      action: "read",
      resource: "ProviderRoute",
      breadcrumb: [
        {
          text: 'Job Posts',
          active: true,
        }
      ],
      requireAuth: true,
      logPageName: "Provider Job Posts",
      roles: ["provider"]
    },
  },
  {
    path: '/job-provider/jobs/:job_id/preview',
    name: 'provider-preview-post',
    component: () => import('@/pages/provider/job-posts/preview'),
    meta: {
      pageTitle: 'Preview Job',
      action: "read",
      resource: "ProviderRoute",
      breadcrumb: [
        {
          text: 'Jobs',
          to: {
            name: 'provider-home'
          },
        },
        {
          text: 'Preview Job',
          active: true,
        }
      ],
      requireAuth: true,
      logPageName: "Provider Preview Job",
      roles: ["provider"]
    },
  },
  {
    path: '/job-provider/jobs/:job_id/applications',
    name: 'provider-post-candidates',
    component: () => import('@/pages/provider/job-posts/view'),
    meta: {
      pageTitle: 'CV Submissions',
      action: "read",
      resource: "ProviderRoute",
      breadcrumb: [
        {
          text: 'Jobs',
          to: {
            name: 'provider-home'
          },
        },
        {
          text: 'Candidates',
          active: true,
        }
      ],
      requireAuth: true,
      logPageName: "Provider Job Candidates",
      roles: ["provider"]
    },
  },
  {
    path: '/job-provider/company-profile',
    name: 'company-profile',
    component: () => import('@/pages/provider/company'),
    meta: {
      pageTitle: 'Company',
      action: "read",
      resource: "ProviderRoute",
      breadcrumb: [
        {
          text: 'Home',
          active: true,
        }
      ],
      requireAuth: true,
      logPageName: "Job Provider Company",
      roles: ["provider"]
    },
  },
  {
    path: '/job-provider/candidates',
    name: 'candidates',
    component: () => import('@/pages/provider/candidates/list'),
    meta: {
      pageTitle: 'Candidates',
      action: "read",
      resource: "ProviderRoute",
      breadcrumb: [
        {
          text: 'Home',
          active: true,
        }
      ],
      requireAuth: true,
      logPageName: "Job Provider Candidates",
      roles: ["provider"]
    },
  },
  {
    path: '/job-provider/candidates/:id',
    name: 'single-candidate',
    component: () => import('@/pages/provider/candidates/view'),
    meta: {
      pageTitle: 'Candidate\'s CV',
      action: "read",
      resource: "ProviderRoute",
      breadcrumb: [
        {
          text: 'Home',
          to: { name: 'provider-home' },
        },
        {
          text: 'Candidates',
          to: { name: 'candidates' },
        },
        {
          text: 'CV',
          active: true,
        }
      ],
      requireAuth: true,
      logPageName: "Job Provider Candidate's CV",
      roles: ["provider"]
    },
  },
  {
    path: '/job-provider/my-candidates',
    name: 'my-candidates',
    component: () => import('@/pages/provider/my-candidates'),
    meta: {
      pageTitle: 'My Candidates',
      action: "read",
      resource: "ProviderRoute",
      breadcrumb: [
        {
          text: 'Home',
          active: true,
        }
      ],
      requireAuth: true,
      logPageName: "Job Provider My Candidates",
      roles: ["provider"]
    },
  },
  {
    path: '/job-provider/application-letters',
    name: 'application-letters',
    component: () => import('@/pages/provider/application-letters'),
    meta: {
      pageTitle: 'Application Letter Requests',
      action: "read",
      resource: "ProviderRoute",
      breadcrumb: [
        {
          text: 'Home',
          active: true,
        }
      ],
      requireAuth: true,
      logPageName: "Job Provider Application Letters",
      roles: ["provider"]
    },
  },
  {
    path: '/job-provider/scheduled-interviews',
    name: 'scheduled-interviews',
    component: () => import('@/pages/provider/scheduled-interviews'),
    meta: {
      pageTitle: 'Scheduled Interviews',
      action: "read",
      resource: "ProviderRoute",
      breadcrumb: [
        {
          text: 'Home',
          active: true,
        }
      ],
      requireAuth: true,
      logPageName: "Job Provider Scheduled Interviews",
      roles: ["provider"]
    },
  },
  {
    path: '/job-provider/shortlisted',
    name: 'shortlisted-candidates',
    component: () => import('@/pages/provider/shortlisted'),
    meta: {
      pageTitle: 'Shortlisted Candidates',
      action: "read",
      resource: "ProviderRoute",
      breadcrumb: [
        {
          text: 'Home',
          active: true,
        }
      ],
      requireAuth: true,
      logPageName: "Job Provider Shortlisted Candidates",
      roles: ["provider"]
    },
  },
]
