<template>
  <div>
    <slot />

    <b-modal
      v-model="showLogoutCountDownModal"
      title="You are Idle"
      title-tag="div"
      centered
      ok-only
      ok-title="Resume"
      ok-variant="success"
      hide-header-close
      no-close-on-backdrop
      no-close-on-esc
      @ok="onResumeFromIdle()"
      @show="onShowingIdleModal()"
      @hide="onHidingIdleModal()"
    >
      <b-alert variant="warning" show>
        You have been idle for {{ wait_time }} minutes. You'll be logged out in {{ idle_time }}
      </b-alert>
    </b-modal>
    
  </div>
</template>

<script>
import { BAlert } from "bootstrap-vue"
import { Idle } from "idlejs"

export default {
  components: {
    BAlert
  },
  props: {
    duration: {
      type: Number,
      // default 5 minutes
      default: 60 * 5,
    },
    loop: {
      type: Boolean,
      default: false,
    },
    reminders: {
      type: Array,
      default: () => []
    },
    wait: {
      type: Number,
      // default 5 minutes
      default: 60 * 5,
    },
  },
  data() {
    return {
      plug_idle_js: null,

      idle_time: `${(this.duration / 60) - 5}:00`,
      wait_time: 5,

      start: 0,
      diff: 0,

      minutes: '',
      seconds: '',
      countdown_interval_ids: [],

      // modal related
      showLogoutCountDownModal: false,
    }
  },
  watch: {
    isLoggedIn: {
      handler(loggedIn){
        const env = process.env.NODE_ENV
        if (loggedIn && env === 'production'){
          this.setupIdleCheck();
        } else {
          this.cleanAndStopIdleCheck()
        }
      }
    }
  },
  created() {
    localStorage.setItem("tabSyncEvent", "reset");
    window.addEventListener('storage', () => {
      const event = localStorage.getItem('tabSyncEvent');
      if (event === "show") {
        this.showLogoutCountDownModal = true;
      } else if (event === "hide") {
        this.showLogoutCountDownModal = false;
      } else if (event === "reset") {
        if (this.showLogoutCountDownModal) {
          this.showLogoutCountDownModal = false;
        }
        this.resetLogoutCountDown();
      }
    });
  },
  beforeDestroy() {
    this.cleanAndStopIdleCheck()
  },
  methods: {
    setupIdleCheck(){
      if (!this.plug_idle_js){
        // console.log('x--> initializing idle check')
        this.plug_idle_js = new Idle()
        .whenNotInteractive()
        .within(this.wait_time)
        .do(this.onIdleUser)
        .repeat(false)
        .start();
      } else {
        // console.log('x--> stop and restart idle check')
        this.plug_idle_js.stop()
        this.plug_idle_js = null;
        this.setupIdleCheck()
      }
    },
    onIdleUser(){
      // console.log("x--> user is idle...")
      this.showLogoutCountDownModal = true;
      this.idle_time = `${(this.duration / 60) - this.wait_time}:00`;
    },
    onResumeFromIdle(){
      this.showLogoutCountDownModal = false;
    },
    onUpdateLogoutTimer() {
      // console.log("x--> diff", this.diff)
      // seconds since start
      // eslint-disable-next-line no-bitwise
      this.diff = (this.duration - (this.wait_time * 60)) - (((Date.now() - this.start) / 1000) | 0)
      if (this.diff < 0 && !this.loop) {
        this.showLogoutCountDownModal = false
        this.logout();
        return
      }

      // bitwise OR to handle parseInt
      // eslint-disable-next-line no-bitwise
      const minute = (this.diff / 60) | 0
      // eslint-disable-next-line no-bitwise
      const second = this.diff % 60 | 0

      this.minutes = `${minute < 10 ? '0' + minute : minute}`
      this.seconds = `${second < 10 ? '0' + second : second}`

      this.idle_time = `${this.minutes}:${this.seconds}`

      if (this.idle_time === "00:00"){
        // console.log('x--> zzz', this.idle_time);
        this.showLogoutCountDownModal = false
        this.logout();
      }
    },
    onShowingIdleModal(){
      this.resetLogoutCountDown()
      localStorage.setItem("tabSyncEvent", "show");
      this.diff = 0
      // add second to start at the full duration
      // for instance 05:00, not 04:59
      this.start = Date.now() + 1000

      const interval_id = setInterval(this.onUpdateLogoutTimer, 1000);
      this.countdown_interval_ids.push(interval_id);
    },
    onHidingIdleModal(){
      this.resetLogoutCountDown();
      localStorage.setItem("tabSyncEvent", "hide");
    },
    resetLogoutCountDown(){
      this.countdown_interval_ids.forEach(idle_time_out_id => {
        clearTimeout(idle_time_out_id);
      });

      this.countdown_interval_ids = [];
    },
    cleanAndStopIdleCheck(){
      if (this.showLogoutCountDownModal){
        // console.log('x--> cleanup idle check');
        this.showLogoutCountDownModal = false;
        this.resetLogoutCountDown()
      }

      if (this.plug_idle_js){
        // console.log('x--> stop idle check');
        this.plug_idle_js.stop()
      }
    }
  },
}
</script>
