

export default {
  namespaced: true,
  state: {
    query: {},
    perPage: 50,
    currentPage: 1,
    totalRecords: 0,
    isFetchingRemoteData: false,
    perPageOptions: [50, 100, 150, 250, 500],
  },
  getters: {
    routeQuery: state => state.query,
    recordsPerPage: state => state.perPage,
    currentPage: state => state.currentPage,
    totalRecords: state => state.totalRecords,
    recordsPerPageOptions: state => state.perPageOptions,
    isFetchingRemoteData: state => state.isFetchingRemoteData
  },
  mutations: {
    UPDATE_RECORDS_PER_PAGE(state, val) {
      state.perPage = val
    },
    UPDATE_CURRENT_PAGE_NUMBER(state, val) {
      state.currentPage = val
    },
    UPDATE_TOTAL_RECORDS(state, val) {
      state.totalRecords = val
    },
    UPDATE_FETCHING_REMOTE_DATA(state, val) {
      state.isFetchingRemoteData = val
    }
  },
  actions: {},
}
